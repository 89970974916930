import React from "react";
import { createContext, useState, ReactNode, FC, useEffect } from "react";
import { doc, getDoc } from "firebase/firestore";
import { auth, firestore } from "../firebase";
import { UserData } from "../types/types";
import { onAuthStateChanged } from "firebase/auth";

interface UserContextState {
  user: UserData | null;
  fetchUser: (userId: string) => void;
}

export const UserContext = createContext<UserContextState | undefined>(
  undefined,
);

interface UserProviderProps {
  children: ReactNode;
}

export const UserProvider: FC<UserProviderProps> = ({ children }) => {
  const [user, setUser] = useState<UserData | null>(null);

  const fetchUser = async (userId?: string) => {
    if (!userId) {
      setUser(null);
      return;
    }
    const userDocRef = doc(firestore, "users", userId);
    const docSnap = await getDoc(userDocRef);

    if (docSnap.exists()) {
      setUser(docSnap.data() as UserData);
    } else {
      setUser(null);
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        fetchUser(currentUser.uid);
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  return (
    <UserContext.Provider value={{ user, fetchUser }}>
      {children}
    </UserContext.Provider>
  );
};
