import React from "react";
import { ReactNode } from "react";
import { UserProvider } from "./UserProvider";
import { PurchasedPatternsProvider } from "./PurchasedPatternsProvider";
import { AuthProvider } from "./AuthProvider";
import { CartProvider } from "./CartProvider";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { CurrencyProvider } from "./CurrencyProvider";

interface ContextProviderProps {
  children: ReactNode;
}

const stripePromise = loadStripe(
  process.env.GATSBY_STRIPE_PUBLISHABLE_KEY as string,
);

const ContextProvider: React.FC<ContextProviderProps> = ({ children }) => {
  return (
    <AuthProvider>
      <UserProvider>
        <PurchasedPatternsProvider>
          <CurrencyProvider>
            <CartProvider>
              <Elements stripe={stripePromise}>{children}</Elements>
            </CartProvider>
          </CurrencyProvider>
        </PurchasedPatternsProvider>
      </UserProvider>
    </AuthProvider>
  );
};

export default ContextProvider;
