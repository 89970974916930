import { createContext, useState, ReactNode, FC, useEffect } from "react";
import { CartItem, DiscountInfo } from "../types/types";

interface AppState {
  cart: CartItem[];
  addToCart: (item: CartItem) => void;
  removeFromCart: (itemId: string) => void;
  clearCart: () => void;
  isCartOpen: boolean;
  openCart: () => void;
  closeCart: () => void;
  promoCode: string;
  setPromoCode: (code: string) => void;
  discountInfo: DiscountInfo;
  setDiscountInfo: (info: DiscountInfo) => void;
}

export const CartContext = createContext<AppState | undefined>(undefined);

interface CartProviderProps {
  children: ReactNode;
}

export const CartProvider: FC<CartProviderProps> = ({ children }) => {
  const [cart, setCart] = useState<CartItem[]>(() => {
    if (typeof window !== "undefined") {
      const savedCart = localStorage.getItem("shoppingCart");
      return savedCart ? JSON.parse(savedCart) : [];
    }
    return [];
  });
  const [promoCode, setPromoCode] = useState(() => {
    if (typeof window !== "undefined") {
      const savedPromoCode = localStorage.getItem("promoCode");
      return savedPromoCode || "";
    }
    return "";
  });

  const [isCartOpen, setIsCartOpen] = useState(false);
  const [discountInfo, setDiscountInfo] = useState<DiscountInfo>({
    isValid: false,
    discountAmount: 0,
  });

  const addToCart = (item: CartItem) => {
    setCart((prevCart) => {
      const itemIndex = prevCart.findIndex(
        (cartItem) => cartItem.id === item.id,
      );
      if (itemIndex > -1) {
        return prevCart;
      } else {
        return [...prevCart, item];
      }
    });
  };

  const removeFromCart = (itemId: string) => {
    setCart((prevCart) => prevCart.filter((item) => item.id !== itemId));
    setPromoCode("");
    setDiscountInfo({ isValid: false, discountAmount: 0 });
  };

  const clearCart = () => {
    setCart([]);
  };

  const openCart = () => setIsCartOpen(true);
  const closeCart = () => setIsCartOpen(false);

  const value = {
    cart,
    addToCart,
    removeFromCart,
    clearCart,
    isCartOpen,
    openCart,
    closeCart,
    promoCode,
    setPromoCode,
    discountInfo,
    setDiscountInfo,
  };

  useEffect(() => {
    localStorage.setItem("shoppingCart", JSON.stringify(cart));
    localStorage.setItem("promoCode", promoCode);
  }, [cart, promoCode]);

  return <CartContext.Provider value={value}>{children}</CartContext.Provider>;
};
