exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-index-tsx": () => import("./../../../src/pages/about/index.tsx" /* webpackChunkName: "component---src-pages-about-index-tsx" */),
  "component---src-pages-cart-index-tsx": () => import("./../../../src/pages/cart/index.tsx" /* webpackChunkName: "component---src-pages-cart-index-tsx" */),
  "component---src-pages-checkout-index-tsx": () => import("./../../../src/pages/checkout/index.tsx" /* webpackChunkName: "component---src-pages-checkout-index-tsx" */),
  "component---src-pages-contact-index-tsx": () => import("./../../../src/pages/contact/index.tsx" /* webpackChunkName: "component---src-pages-contact-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-index-tsx": () => import("./../../../src/pages/login/index.tsx" /* webpackChunkName: "component---src-pages-login-index-tsx" */),
  "component---src-pages-logout-index-tsx": () => import("./../../../src/pages/logout/index.tsx" /* webpackChunkName: "component---src-pages-logout-index-tsx" */),
  "component---src-pages-mypatterns-index-tsx": () => import("./../../../src/pages/mypatterns/index.tsx" /* webpackChunkName: "component---src-pages-mypatterns-index-tsx" */),
  "component---src-pages-mypreferences-index-tsx": () => import("./../../../src/pages/mypreferences/index.tsx" /* webpackChunkName: "component---src-pages-mypreferences-index-tsx" */),
  "component---src-pages-patterns-index-tsx": () => import("./../../../src/pages/patterns/index.tsx" /* webpackChunkName: "component---src-pages-patterns-index-tsx" */),
  "component---src-pages-privacy-policy-index-tsx": () => import("./../../../src/pages/privacy-policy/index.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-index-tsx" */),
  "component---src-pages-refund-policy-index-tsx": () => import("./../../../src/pages/refund-policy/index.tsx" /* webpackChunkName: "component---src-pages-refund-policy-index-tsx" */),
  "component---src-pages-shipping-policy-index-tsx": () => import("./../../../src/pages/shipping-policy/index.tsx" /* webpackChunkName: "component---src-pages-shipping-policy-index-tsx" */),
  "component---src-pages-success-index-tsx": () => import("./../../../src/pages/success/index.tsx" /* webpackChunkName: "component---src-pages-success-index-tsx" */),
  "component---src-pages-terms-of-service-index-tsx": () => import("./../../../src/pages/terms-of-service/index.tsx" /* webpackChunkName: "component---src-pages-terms-of-service-index-tsx" */),
  "component---src-pages-videos-index-tsx": () => import("./../../../src/pages/videos/index.tsx" /* webpackChunkName: "component---src-pages-videos-index-tsx" */),
  "component---src-templates-pattern-details-pattern-details-tsx": () => import("./../../../src/templates/patternDetails/patternDetails.tsx" /* webpackChunkName: "component---src-templates-pattern-details-pattern-details-tsx" */)
}

