import { createContext, useState, ReactNode, FC, useEffect } from "react";
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { PatternData, PurchasedPattern } from "../types/types";
import { useFetchPatterns } from "../hooks/useFetchPatterns";
import { useAuth } from "../hooks/useAuthContext";

interface PurchasedPatternsContextState {
  purchasedPatterns: Record<string, PurchasedPattern[]>;
  fetchPurchasedPatterns: () => void;
}

export const PurchasedPatternsContext = createContext<
  PurchasedPatternsContextState | undefined
>(undefined);

interface PurchasedPatternsProviderProps {
  children: ReactNode;
}

export const PurchasedPatternsProvider: FC<PurchasedPatternsProviderProps> = ({
  children,
}) => {
  const [purchasedPatterns, setPurchasedPatterns] = useState<
    Record<string, PurchasedPattern[]>
  >({});
  const { user } = useAuth();
  const allPatterns: PatternData[] = useFetchPatterns();

  const fetchPurchasedPatterns = async () => {
    if (!user) {
      setPurchasedPatterns({});
      return;
    }

    const db = getFirestore();
    const purchasesCol = collection(db, "purchases");
    const purchasesQuery = query(purchasesCol, where("userId", "==", user.uid));
    const purchasesSnapshot = await getDocs(purchasesQuery);

    if (purchasesSnapshot.empty) {
      setPurchasedPatterns({});
      return;
    }

    const ordersData: Record<string, PurchasedPattern[]> = {};

    purchasesSnapshot.docs.forEach((purchaseDoc) => {
      const purchaseData = purchaseDoc.data();

      purchaseData.patternId.forEach((id: string) => {
        const pattern = allPatterns.find((p) => p.id === id);
        if (pattern && purchaseData.paymentStatus === "succeeded") {
          // If the pattern is a pack, expand it
          if (pattern.packPatternIds && pattern.packPatternIds.length > 0) {
            pattern.packPatternIds.forEach((packPatternId) => {
              const packPattern = allPatterns.find(
                (p) => p.id === packPatternId,
              );
              if (packPattern) {
                const purchasedPattern: PurchasedPattern = {
                  patternId: packPattern.id!,
                  name: `${pattern.patternName.en} - ${packPattern.patternName.en}`,
                  purchaseDate: purchaseData.purchaseDate.toDate(),
                  amount: purchaseData.amount,
                  currency: purchaseData.currency,
                  orderId: purchaseData.orderId,
                  files: packPattern.files,
                  mainImage: packPattern.mainImage,
                  paymentStatus: purchaseData.paymentStatus,
                };

                if (!ordersData[purchaseData.orderId]) {
                  ordersData[purchaseData.orderId] = [];
                }
                ordersData[purchaseData.orderId].push(purchasedPattern);
              }
            });
          } else {
            const purchasedPattern: PurchasedPattern = {
              patternId: id,
              name: pattern.patternName.en,
              purchaseDate: purchaseData.purchaseDate.toDate(),
              amount: purchaseData.amount,
              currency: purchaseData.currency,
              orderId: purchaseData.orderId,
              files: pattern.files,
              mainImage: pattern.mainImage,
              paymentStatus: purchaseData.paymentStatus,
            };

            if (!ordersData[purchaseData.orderId]) {
              ordersData[purchaseData.orderId] = [];
            }
            ordersData[purchaseData.orderId].push(purchasedPattern);
          }
        }
      });
    });

    setPurchasedPatterns(ordersData);
  };

  useEffect(() => {
    fetchPurchasedPatterns();
  }, [user]);

  return (
    <PurchasedPatternsContext.Provider
      value={{ purchasedPatterns, fetchPurchasedPatterns }}
    >
      {children}
    </PurchasedPatternsContext.Provider>
  );
};
