import { useStaticQuery, graphql } from "gatsby";
import { PatternData } from "../types/types";

interface Data {
  allPattern: {
    nodes: PatternData[];
  };
}

export const useFetchPatterns = () => {
  const data: Data = useStaticQuery(graphql`
    query PatternsQuery {
      allPattern {
        nodes {
          id
          patternName {
            en
            pt
          }
          description {
            en
            pt
          }
          materials {
            en
            pt
          }
          category
          adjustmentType
          adjustmentAmount
          initialPrice {
            usd
            brl
          }
          price {
            usd
            brl
          }
          mainImage
          secondaryImages
          localMainImage {
            childImageSharp {
              gatsbyImageData(
                width: 600
                placeholder: BLURRED
                layout: CONSTRAINED
              )
            }
          }
          localSecondaryImages {
            childImageSharp {
              gatsbyImageData(
                width: 300
                placeholder: BLURRED
                layout: CONSTRAINED
              )
            }
          }
          files {
            language
            originalName
          }
          feedbacks {
            review
            creationDate
            imageUrl
          }
          uploadDate
          packPatternIds
        }
      }
    }
  `);

  return data.allPattern.nodes;
};
